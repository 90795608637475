import React from 'react'
import {Container, Box, Grid, Typography} from '@mui/material'
import logo from '../assets/logo.png'
import x from '../assets/x.png'

// import linkedin from '../assets/linkedin.png'
// import manifestPowered from '../assets/manifest-powered.webp'
import Newsletter from '../components/Newsletter'
export default function Component() {
  return (
    <Container component="footer" sx={{pb: 8}} maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            component="img"
            src={logo}
            sx={{
              maxWidth: 170,
              display: 'block',
              margin: {xs: '0 auto', sm: '0'},
            }}
          />
          <Typography
            component="h1"
            className="inter-tight"
            sx={{
              mt: 0.5,
              fontSize: '.64rem',
              textAlign: {xs: 'center', sm: 'left'},
            }}
          >
            Catalyzing the intelligence community
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{
            justifyContent: {xs: 'center', sm: 'left'},
          }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              justifyContent: {xs: 'center', sm: 'left'},
            }}
          >
            <Newsletter />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              justifyContent: {xs: 'center', sm: 'left'},
              mb: {xs: 2, sm: 0},
            }}
          >
            <Box
              component="a"
              href="https://x.com/focaldao"
              sx={{
                display: 'flex',
                width: 30,
                height: 30,
                background: '#fff',
                borderRadius: 4,
                p: 0.5,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component="img" src={x} sx={{width: 20}} />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          sx={{
            justifyContent: {xs: 'center', sm: 'right'},
            alignItems: 'flex-end',
            textAlign: {xs: 'center', sm: 'right'},
          }}
        >
          <Typography
            component="p"
            variant="p"
            className="inter-tight"
            // align={{xs: 'center', sm: 'right'}}
            sx={{fontSize: 12}}
          >
            &copy; {new Date().getUTCFullYear()} FocalDAO. All Rights Reserved.​
          </Typography>
        </Grid>
        {/* <Grid item xs={12}>
          <Box
            component="a"
            href="https://liftedinit.org/#/hosting"
            underline="none"
            sx={{display: 'block', my: 3, mx: 'auto', width: 200}}
          >
            <Box
              component="img"
              src={manifestPowered}
              style={{maxWidth: '100%'}}
              alt="Powered by The Manifest Network"
              sx={{boxShadow: '0 1px 9px rgba(0, 0, 0, 0.1)'}}
            />
          </Box>
        </Grid> */}
      </Grid>
    </Container>
  )
}
