import React, {useEffect, useState} from 'react'

import {
  FormGroup,
  FormControl,
  TextField,
  Typography,
  Grid,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import emailjs from '@emailjs/browser'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorIcon from '@mui/icons-material/Error'

const defaultState = {
  name: '',
  project: '',
  email: '',
  website: '',
  message: '',
  isNewsletter: true,
}

export default function Component() {
  const [formState, setFormState] = useState(defaultState)
  const [isComplete, setIsComplete] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const emailValid = formState.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    setIsValid(emailValid)
  }, [formState])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    emailjs.init('3Aukb22LRkm8NTuUU')
    emailjs.send('service_8xdlg7q', 'template_ifst9mi', formState).then(
      (response) => {
        setIsSubmitting(false)
        setIsComplete(true)
      },
      (error) => {
        setIsSubmitting(false)
        setHasError(true)
      }
    )
  }
  return (
    <>
      {isComplete || hasError ? (
        <>
          <Grid
            container
            sx={{
              mt: 2,
              mb: 4,
              maxWidth: 200,
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {hasError ? (
              <>
                <ErrorIcon
                  sx={{fontSize: 36, color: '#fff', mr: 2, ml: -0.5}}
                />

                <Typography
                  component="p"
                  variant="p"
                  align="center"
                  className="inter-tight"
                >
                  An Error Occurred
                </Typography>
              </>
            ) : (
              <>
                <CheckCircleOutlinedIcon
                  sx={{fontSize: 36, color: '#fff', mr: 2, ml: -0.5}}
                />

                <Typography
                  component="p"
                  variant="p"
                  align="center"
                  className="inter-tight"
                >
                  Stay tuned.
                </Typography>
              </>
            )}
          </Grid>
        </>
      ) : (
        <FormGroup sx={{mt: 2, mb: 4, maxWidth: 300}} className="inter-tight">
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  variant="standard"
                  value={formState.email}
                  onChange={(ev) => {
                    setFormState({
                      ...formState,
                      email: ev.target.value,
                    })
                  }}
                  placeholder="Sign Up for Our Newsletter"
                  sx={{
                    py: 1,
                    '& .MuiInput-underline:after': {
                      borderBottomColor: '#fff',
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <LoadingButton
                onClick={handleSubmit}
                loading={isSubmitting}
                disabled={!isValid}
                sx={{
                  background: '#fff',
                  color: '#000',
                  height: 42,
                  '&.MuiLoadingButton-loading': {color: '#fff'},
                  '&:hover': {background: '#f1f1f1'},
                  '&:disabled': {background: '#333'},
                }}
              >
                {isSubmitting ? '' : <KeyboardArrowRightIcon />}
              </LoadingButton>
            </Grid>
          </Grid>
        </FormGroup>
      )}
    </>
  )
}
