import React, {useEffect} from 'react'
import {Routes, Route, useLocation} from 'react-router-dom'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Footer from './components/Footer'

import Landing from './pages/Landing'
// import NotFound from './pages/NotFound'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

function App() {
  let location = useLocation()

  useEffect(() => {
    const anchor = window.location.hash.slice(1)
    if (anchor) {
      const anchorEl = document.getElementById(anchor)
      if (anchorEl) {
        anchorEl.scrollIntoView()
      }
    }
  }, [location])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Landing />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
      <Footer />
    </ThemeProvider>
  )
}

export default App
