import React, {useEffect, useState} from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import AutoScroll from 'embla-carousel-auto-scroll'
// import {
//   NextButton,
//   PrevButton,
//   usePrevNextButtons
// } from './EmblaCarouselArrowButtons'

const Carousel = (props) => {
  const {slides, options} = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({speed: 1.1, stopOnInteraction: false}),
  ])
  const [, setIsPlaying] = useState(false)

  //   const {
  //     prevBtnDisabled,
  //     nextBtnDisabled,
  //     onPrevButtonClick,
  //     onNextButtonClick
  //   } = usePrevNextButtons(emblaApi)

  //   const onButtonAutoplayClick = useCallback(
  //     (callback) => {
  //       const autoScroll = emblaApi?.plugins()?.autoScroll
  //       if (!autoScroll) return

  //       const resetOrStop =
  //         autoScroll.options.stopOnInteraction === false
  //           ? autoScroll.reset
  //           : autoScroll.stop

  //       resetOrStop()
  //       callback()
  //     },
  //     [emblaApi]
  //   )

  //   const toggleAutoplay = useCallback(() => {
  //     const autoScroll = emblaApi?.plugins()?.autoScroll
  //     if (!autoScroll) return

  //     const playOrStop = autoScroll.isPlaying()
  //       ? autoScroll.stop
  //       : autoScroll.play
  //     playOrStop()
  //   }, [emblaApi])

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    setIsPlaying(autoScroll.isPlaying())
    emblaApi
      .on('autoScroll:play', () => setIsPlaying(true))
      .on('autoScroll:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoScroll.isPlaying()))
  }, [emblaApi])

  useEffect(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return

    autoScroll.play()
  }, [emblaApi])

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((el, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
                <span>{el}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__buttons">
          {/* <PrevButton
            onClick={() => onButtonAutoplayClick(onPrevButtonClick)}
            disabled={prevBtnDisabled}
          />
          <NextButton
            onClick={() => onButtonAutoplayClick(onNextButtonClick)}
            disabled={nextBtnDisabled}
          /> */}
        </div>

        {/* <button className="embla__play" onClick={toggleAutoplay} type="button">
          {isPlaying ? 'Stop' : 'Start'}
        </button> */}
      </div>
    </div>
  )
}

export default Carousel
