import React, {useEffect, useRef} from 'react'
import {Box, Container, Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Link as ScrollLink, Element as ScrollElement} from 'react-scroll'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import Contact from '../components/Contact'
import logo from '../assets/logo.png'
import hero from '../assets/hero2.jpg'

import p0 from '../assets/p-treasury.png'
import p1 from '../assets/p-cures.png'
import p2 from '../assets/p-future.png'
import p3 from '../assets/p-lifted.png'
import p4 from '../assets/p-lily.png'
import p5 from '../assets/p-manifest.png'
import p6 from '../assets/p-morpheus.png'
import p7 from '../assets/p-second.png'
import p8 from '../assets/p-6079b.png'

import Carousel from '../components/Carousel'

const partners = [
  {
    src: p0,
    alt: 'AI Treasury',
    link: 'https://aitreasury.org',
  },
  {
    src: p1,
    alt: 'Cures Collective',
    link: 'https://curescollective.com',
  },
  {
    src: p2,
    alt: 'Futureverse',
    link: 'https://futureverse.ccom',
  },
  {
    src: p3,
    alt: 'The Lifted Initiative',
    link: 'https://liftedinit.org',
  },
  {
    src: p4,
    alt: 'Lilypad Network',
    link: 'https://blog.lilypadnetwork.org',
  },
  {
    src: p5,
    alt: 'Manifest AI',
    link: 'https://manifestai.org',
  },
  {
    src: p6,
    alt: 'Morpheus AII',
    link: 'https://morpheusai.org',
  },
  {
    src: p7,
    alt: 'Second Life',
    link: '',
  },
  {
    src: p8,
    alt: '6079',
    link: 'https://6079.io',
  },
]

export default function Component() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener(
        'loadeddata',
        () => {
          videoRef.current.classList.add('fade_in--loaded')
        },
        false
      )
    }
  })

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          p: 0,
          position: 'relative',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            position: 'absolute',
            bottom: 0,
            // pt: {xs: 25, sm: 44, md: 62, lg: 68},
            zIndex: 1,
          }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              display: 'block',
              maxWidth: {xs: 210, sm: 300, md: 380},
              maxHeight: 80,
              margin: {xs: '0 auto', sm: 0},
            }}
          />
          <Typography
            component="h1"
            sx={{
              mt: 1,
              mb: 4,
              textShadow: '1px 1px 1px #000',
              fontSize: {xs: 13, sm: 18, md: 23},
              textAlign: {xs: 'center', sm: 'left'},
            }}
            className="inter-tight"
          >
            Catalyzing the intelligence community
          </Typography>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Box
              sx={{cursor: 'pointer', width: 'auto', opacity: 0.8}}
              className="float-y"
            >
              <ScrollLink to="section2" smooth={true} duration={500}>
                <KeyboardDoubleArrowDownIcon fontSize="large" />
              </ScrollLink>
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundImage: `url(${hero})`,
          }}
        >
          {!isMobile && (
            <Box
              component="video"
              className="fade_in"
              autoPlay
              muted
              loop
              ref={videoRef}
              sx={{width: '100%', height: '100%'}}
            >
              <source
                src="https://liftedinit.s3.amazonaws.com/focaldao/video-3.webm"
                type="video/webm"
              />
            </Box>
          )}
        </Box>
      </Container>

      <ScrollElement name="section2" />

      <Container maxWidth="xl">
        <Typography
          component="h1"
          className="inter-tight"
          align="center"
          sx={{fontWeight: 600, fontSize: {xs: 23, md: 32}, mb: 8, mt: 28}}
        >
          We cultivate the world’s top decentralized AI initiatives.
        </Typography>

        <Carousel
          slides={partners.map((p, i) => (
            <Box sx={{minHeight: 80, minWidth: 80}} key={`p-${i}`}>
              <Box
                sx={{
                  width: '100%',
                  height: '80px',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <a
                  href={p.link}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    component="img"
                    src={p.src}
                    alt={p.alt}
                    sx={{
                      margin: '0 auto',
                      objectFit: 'scale-down',
                      maxWidth: '160px',
                      maxHeight: '80%',
                      filter: 'grayscale(1)',
                    }}
                  />
                </a>
              </Box>
            </Box>
          ))}
          options={{loop: true}}
        />
      </Container>
      <Container
        // maxWidth="sm"
        sx={{
          mt: 28,
          mb: 28,
          '&.MuiContainer-root': {maxWidth: 560},
        }}
      >
        <Typography component="p" className="inter-tight" align="center">
          FocalDAO is the premier community that scales the world’s top
          decentralized AI initiatives. We bring together promising DeAI
          projects, motivated builders, and passionate contributors. With this
          unprecedented ecosystem collaboration, we establish global industry
          standards and sustainable commercialization, to ultimately make our
          shared visions of an open, fair future a reality.
        </Typography>
      </Container>
      <Container maxWidth="xl">
        <Contact />
      </Container>
    </>
  )
}
