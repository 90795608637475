import {
  FormGroup,
  Container,
  TextField,
  FormControl,
  Typography,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Grid} from '@mui/material'
import emailjs from '@emailjs/browser'
// import MailIcon from '@mui/icons-material/Mail'
import LoadingButton from '@mui/lab/LoadingButton'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorIcon from '@mui/icons-material/Error'

const defaultState = {
  name: '',
  project: '',
  email: '',
  website: '',
  message: '',
  isNewsletter: false,
}

export default function Component() {
  const [formState, setFormState] = useState(defaultState)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const emailValid = formState.email.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    const nameValid = formState.name.length > 1
    const projectValid = formState.project.length > 1
    const websiteValid = formState.website.match(/\.[a-z]{2,6}$/)
    setIsValid(emailValid && projectValid && nameValid && websiteValid)
  }, [formState])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    setHasError(true)
    emailjs.init('3Aukb22LRkm8NTuUU')
    emailjs.send('service_8xdlg7q', 'template_ifst9mi', formState).then(
      (response) => {
        setIsSubmitting(false)
        setIsComplete(true)
      },
      (error) => {
        setIsSubmitting(false)
        setHasError(true)
      }
    )
  }

  return (
    <Container maxWidth="sm" sx={{mt: {xs: 5, sm: 15}, mb: 30}}>
      <Typography
        align="center"
        component="h3"
        className="inter-tight"
        size="h3"
        sx={{fontWeight: 600, fontSize: {xs: 23, md: 32}, mb: 2}}
      >
        Build DeAI With Us
      </Typography>

      <Typography
        align="center"
        component="p"
        className="inter-tight"
        sx={{mb: 5}}
      >
        We currently select projects in infrastructure standardization and
        real-world applications of healthcare, finance, and entertainment. Apply
        to seek access to our expertise, partnerships, markets, and swaps.
      </Typography>

      {isComplete || hasError ? (
        <>
          {hasError ? (
            <Grid container item xs={12} sx={{my: 12}}>
              <Grid item container xs={12}>
                <ErrorIcon
                  sx={{fontSize: 48, color: '#fff', margin: '0 auto'}}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                align="center"
                justifyContent={'center'}
              >
                <Typography
                  component="p"
                  variant="p"
                  align="center"
                  className="inter-tight"
                >
                  An Error Occurred
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container item xs={12} sx={{my: 12}}>
              <Grid item container xs={12}>
                <CheckCircleOutlinedIcon
                  sx={{fontSize: 48, color: '#fff', margin: '0 auto'}}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                align="center"
                justifyContent={'center'}
              >
                <Typography
                  component="p"
                  variant="p"
                  align="center"
                  className="inter-tight"
                >
                  Thank you.
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <>
          <FormGroup>
            <Grid container spacing={2} className="inter-tight">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    variant="standard"
                    value={formState.name}
                    onChange={(ev) => {
                      setFormState({
                        ...formState,
                        name: ev.target.value,
                      })
                    }}
                    placeholder="Your Name*"
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#fff',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    variant="standard"
                    value={formState.project}
                    onChange={(ev) => {
                      setFormState({
                        ...formState,
                        project: ev.target.value,
                      })
                    }}
                    placeholder="DeAI Project*"
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#fff',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    variant="standard"
                    value={formState.website}
                    onChange={(ev) => {
                      setFormState({
                        ...formState,
                        website: ev.target.value,
                      })
                    }}
                    placeholder="Website*"
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#fff',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    variant="standard"
                    value={formState.email}
                    onChange={(ev) => {
                      setFormState({
                        ...formState,
                        email: ev.target.value,
                      })
                    }}
                    placeholder="Email*"
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#fff',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    variant="standard"
                    value={formState.message}
                    onChange={(ev) => {
                      setFormState({
                        ...formState,
                        message: ev.target.value,
                      })
                    }}
                    placeholder="Message"
                    sx={{
                      '& .MuiInput-underline:after': {
                        borderBottomColor: '#fff',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormGroup>
          <Grid item xs={12}>
            <LoadingButton
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={!isValid}
              fullWidth
              sx={{
                background: '#fff',
                color: '#000',
                mt: 3,
                p: 1.4,
                height: 42,
                '&.MuiLoadingButton-loading': {color: '#fff'},
                '&:hover': {background: '#f1f1f1'},
                '&:disabled': {background: '#333'},
              }}
            >
              <span>{isSubmitting ? '' : 'Apply'}</span>
            </LoadingButton>
          </Grid>
        </>
      )}
    </Container>
  )
}
